<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

/**
 * Starter page
 */
export default {
    components: { Layout, PageHeader },
    page: {
        title: "Queue Details",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "Queues Details",
            items: [
                {
                    text: "Queue Details",
                },
                {
                    text: "Queues",
                    href: "/monitor/queues",
                },
            ],

            isBusy: false,
            isBusyFailed: false,
            isBusySuccess: false,

            failedQueue: '',
            successEventData: '',

            variation: this.$route.params.id,

            tableData_jobs: [],
            tableData_jobs_success: [],
            tableData_jobs_failed: [],
            error: null,

            totalRows_jobs: 1,
            totalRows_jobs_success: 1,
            totalRows_jobs_failed: 1,

            currentPage_jobs: 1,
            currentPage_jobs_success: 1,
            currentPage_jobs_failed: 1,

            perPage_jobs: 10,
            perPage_jobs_success: 10,
            perPage_jobs_failed: 10,

            pageOptions: [10, 25, 50, 100],

            filter_jobs: null,
            filter_jobs_success: null,
            filter_jobs_failed: null,

            filterOn_jobs: [],
            filterOn_jobs_success: [],
            filterOn_jobs_failed: [],

            sortBy_jobs: "queue",
            sortBy_jobs_success: "queue",
            sortBy_jobs_failed: "id",

            sortDesc: 'ASC',

            fields_jobs: [
                {
                    key: "id",
                    label: "ID",
                    sortable: true,
                },
                {
                    key: "tenant",
                    label: "Tenant",
                    sortable: true,
                },
                {
                    key: "queue",
                    label: "Priority",
                    sortable: true,
                },
                {
                    key: "payload",
                    label: "UUID | Listener",
                    sortable: true,
                },
                {
                    key: "attempts",
                    label: "Attempts",
                    sortable: true,
                },
                {
                    key: "available_at_datetime",
                    label: "Available_at",
                    sortable: true,
                },
                {
                    key: "created_at_datetime",
                    label: "Created_at",
                    sortable: true,
                },
                "action",
            ],

            fields_jobs_failed: [
                {
                    key: "id",
                    label: "ID",
                    sortable: true,
                },
                {
                    key: "uuid",
                    label: "UUID",
                    sortable: true,
                },
                {
                    key: "connection",
                    label: "Connection",
                    sortable: true,
                },
                {
                    key: "payload",
                    label: "Payload",
                    sortable: true,
                },
                {
                    key: "exception",
                    label: "Exception",
                    sortable: true,
                },
                {
                    key: "failed_at",
                    label: "Failed_at",
                    sortable: true,
                },
                "action",
            ],

            fields_jobs_success: [
                {
                    key: "id",
                    label: "ID",
                    sortable: true,
                },
                {
                    key: "uuid",
                    label: "UUID",
                    sortable: true,
                },
                {
                    key: "tenant",
                    label: "Tenant",
                    sortable: true,
                },
                {
                    key: "env",
                    label: "Environment",
                    sortable: true,
                },
                {
                    key: "listener",
                    label: "Listener",
                    sortable: true,
                },
                {
                    key: "event_data",
                    label: "Event_data",
                    sortable: true,
                },
                {
                    key: "started_at",
                    label: "started_at",
                    sortable: true,
                },
                {
                    key: "finished_at",
                    label: "finished_at",
                    sortable: true,
                },
                {
                    key: "exec_time",
                    label: "exec_time",
                    sortable: true,
                },
            ]

        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows_jobs() {
            return this.tableData_jobs.length;
        },
        rows_success_jobs() {
            return this.tableData_jobs_success.length;
        },
        rows_failed_jobs() {
            return this.tableData_jobs_failed.length;
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    async created() {
        this.getQueueJobs(this.variation)
        this.getQueueSuccessJobs(this.variation)
        this.getQueueFailedJobs(this.variation)
    },
    methods:{
        toggleBusyFailed() {
            this.isBusyFailed = !this.isBusyFailed
        },
        toggleBusySuccess() {
            this.isBusySuccess = !this.isBusySuccess
        },
        async getQueueJobs(variation){
            this.toggleBusy()
            try {
                Monitor.getQueueJobs(variation)
                .then(response => {
                    this.tableData_jobs = response.data.data;
                    this.toggleBusy()
                })
                .catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.toggleBusy()
                })
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "Error";
                this.toggleBusy()
            }
        },
        async getQueueSuccessJobs(version){
            this.toggleBusySuccess()
            try {
                Monitor.getQueueSuccessJobs(version)
                .then(response => {
                    this.tableData_jobs_success = response.data.data;
                    this.toggleBusySuccess()
                })
                .catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.toggleBusySuccess()
                })
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "Error";
                this.toggleBusySuccess()
            }
        },
        async getQueueFailedJobs(version){
            this.toggleBusyFailed()
            try {
                Monitor.getQueueFailedJobs(version)
                .then(response => {
                    this.tableData_jobs_failed = response.data.data;
                    this.toggleBusyFailed()
                })
                .catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.toggleBusyFailed()
                })
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "Error";
                this.toggleBusyFailed()
            }
        },

        sendInfo(failedQueue) {
            this.failedQueue = failedQueue
        },
        sendInfoEventData(data) {
            this.successEventData = data
        },
        clearText() {
            this.failedQueue = ''
            this.successEventData = ''
        },
        removeFromQueue(id){
            //TO DO
            console.log(id)
            console.log(this.variation)
            
            Swal.fire({
                title: "Delete Job from Queue",
                text: "This Jobs will be deleted from the Queue!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Move it!",
            }).then((result) => {
                if (result.value) {
                    try {
                        Monitor.deleteJobFromQueue(id, this.variation)
                        .then((response) => {
                            const res = response.data.delete ? response.data.delete : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            if(res){
                                this.getQueueJobs(this.variation)
                                Swal.fire("Success", "Job removed from Queue", "success");
                            }else{
                                Swal.fire("Fail!", error, "warning");
                            }
                        })
                    }catch(error){
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }
                }
            });
        },
        retryFailedJob(uuid){
            //TO DO
            console.log(uuid)
        },
        deleteFailedJob(uuid){
            //TO DO
            console.log(uuid)
        },


    },
    middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">

                    <!-- table 1 -->
                    <h3 class="card-title text-danger">Jobs In Queues</h3>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                        v-model="perPage_jobs"
                                        size="sm"
                                        :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                        v-model="filter_jobs"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                ></b-form-input>
                            </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isBusy"
                                :items="tableData_jobs"
                                :fields="fields_jobs"
                                responsive="sm"
                                :per-page="perPage_jobs"
                                :current-page="currentPage_jobs"
                                :sort-by.sync="sortBy_jobs"
                                :sort-desc.sync="sortDesc"
                                :filter="filter_jobs"
                                :filter-included-fields="filterOn_jobs"
                                show-empty
                                empty-text="No Data Found"
                            >
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                                <template v-slot:cell(payload)="data">
                                    <p>{{ JSON.parse(data.item.payload).uuid}} | {{ JSON.parse(data.item.payload).displayName}}</p>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove from Queue" @click="removeFromQueue(data.item.id)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                        </a>
                                    </ul>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination
                                    v-model="currentPage_jobs"
                                    :total-rows="rows_jobs"
                                    :per-page="perPage_jobs"
                                    ></b-pagination>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end table 1 -->


                    <!-- table 2 -->
                    <h3 class="card-title text-danger mt-5">Failed Jobs</h3>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                        v-model="perPage_jobs_failed"
                                        size="sm"
                                        :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                        v-model="filter_jobs_failed"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                ></b-form-input>
                            </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isBusyFailed"
                                :items="tableData_jobs_failed"
                                :fields="fields_jobs_failed"
                                responsive="sm"
                                :per-page="perPage_jobs_failed"
                                :current-page="currentPage_jobs_failed"
                                :sort-by.sync="sortBy_jobs_failed"
                                :sort-desc.sync="sortDesc"
                                sort-icon-left
                                :filter="filter_jobs_failed"
                                :filter-included-fields="filterOn_jobs_failed"
                                show-empty
                                empty-text="No Data Found"
                            >

                                <template v-slot:cell(payload)="data">
                                    <p>{{ JSON.parse(data.item.payload).displayName}}</p>
                                </template>
                                <template v-slot:cell(exception)="data">
                                    <p>
                                        {{data.item.exception.slice(0, 20)}} ... 
                                        <i class="mdi mdi-2px mdi-format-list-bulleted text-danger" v-b-modal.failed_queue_error @click="sendInfo(data.item.exception)"></i> 
                                    </p>
                                    
                                </template>
                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Retry. Move to Queue" @click="retryFailedJob(data.item.uuid)">
                                            <i class="uil uil-refresh font-size-18"></i>
                                        </a>
                                        <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove Permanent" @click="deleteFailedJob(data.item.uuid)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                        </a>
                                    </ul>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination
                                    v-model="currentPage_jobs_failed"
                                    :total-rows="rows_failed_jobs"
                                    :per-page="perPage_jobs"
                                    ></b-pagination>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end table 2 -->


                    <!-- table 3 -->
                    <h3 class="card-title text-danger mt-5">Success Jobs</h3>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                        v-model="perPage_jobs_success"
                                        size="sm"
                                        :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_filter text-md-end">
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                        v-model="filter_jobs_success"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                ></b-form-input>
                            </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isBusySuccess"
                                :items="tableData_jobs_success"
                                :fields="fields_jobs_success"
                                responsive="sm"
                                :per-page="perPage_jobs_success"
                                :current-page="currentPage_jobs_success"
                                :sort-by.sync="sortBy_jobs_success"
                                :sort-desc.sync="sortDesc"
                                :filter="filter_jobs_success"
                                :filter-included-fields="filterOn_jobs_success"
                                show-empty
                                empty-text="No Data Found"
                            >

                                <template v-slot:cell(event_data)="data">
                                        <i class="mdi mdi-18px mdi-format-list-bulleted text-danger" v-b-modal.success_event_data @click="sendInfoEventData(data.item.event_data)"></i> 
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination
                                    v-model="currentPage_jobs_success"
                                    :total-rows="rows_success_jobs"
                                    :per-page="perPage_jobs_success"
                                    ></b-pagination>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end table 3 -->

                </div>
            </div>
        </div>
    </div>


    <!-- MODALS -->
    <b-modal
        id="failed_queue_error"
        size="xl"
        title="Failed Queue Exception"
        title-class="font-18"
        hide-footer
        @hidden="clearText"
    >
    {{failedQueue}}
    </b-modal>

    <b-modal
        id="success_event_data"
        size="xl"
        title="Event Data"
        title-class="font-18"
        hide-footer
        @hidden="clearText"
    >
    {{successEventData}}
    </b-modal>


  </Layout>
</template>
